import React from 'react';
import  { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import logo1 from './assets/AutoBillsPro Logo(Original) 1.png';



const Forgotpassword = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };


    const inputRefs = useRef([]);

    const handleInputChange = (e, index) => {
      const { value } = e.target;
  
      // Ensure only single digit is allowed
      if (value.length === 1 && !isNaN(value)) {
        if (index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus(); // Move focus to the next input
        }
      } else if (value === '') {
        // Allow backspace to move to previous input
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    };

  return (
    <div className="mx-5 md:mx-[2rem] ">


    <button
          onClick={handleBackClick}
          className="flex items-center w-[70px] h-[60px] md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
  
  
  
  
  
      {/* Right Side Form */}
      <div className="flex flex-col mx-auto justify-center items-center w-full lg:w-1/2 p-6 lg:p-12">
        <img src={logo1} alt="Logo" className="mx-auto mb-6" />
  
  
        <h1 className='text-[36px]'>
  Forgot Password
  </h1>
  
        {/* Toggle between Email and Phone Login */}
       
  <br />
        {/*  */}
        <div className="w-full ">
        <p className='text-[20px] mx-auto font-medium lg:w-[488px]'>
        Enter the OTP code either sent to your email
        or phone number to reset your password
        </p>
        </div>
  
      <br />
      <br />

      <div className="flex justify-evenly w-full">
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleInputChange(e, index)}
          className="border-black border-2 w-[53px] h-[59px] rounded-[10px] p-3 text-[25px] text-center"
        />
      ))}
    </div>


<br />

        {/* Login Button */}
        <Link to="/forgot-password-otp  " className="w-full max-w-md  mt-6">
        <div className='flex justify-center'>
          <button className="bg-[#FF6600] w-full md:w-[374px] h-[62px] rounded-full text-white font-bold">
          Continue
          </button>
          </div>
        </Link>
  
      </div>
    </div>
  )
}

export default Forgotpassword





