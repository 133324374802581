import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import billicon from './assets/billpaymenticon.svg';
import billactiveicon from './assets/billpaymenticonactive.png';
import createbillicon from './assets/createabillicon.svg';
import createbillactiveicon from './assets/createabilliconactive.png';
import dashboardactiveicon from './assets/dashboardiconactive.svg';
import dashboardicon from './assets/dashboardicon.png';
import settingsicon from './assets/settingsicon.svg';
import settingsactiveicon from './assets/settingsiconactive.svg';
import sidenavllogo from './assets/sidenavlogo.png';
import profileicon from './assets/profileicon.svg';
import profileactiveicon from './assets/profileactive.png';
import logouticon from './assets/logouticon.svg';
import cardactive from './assets/cardactiveicon.png';
import cardinactive from './assets/cardinactiveicon.png';
import whitewithorange from './assets/AutoBillsPro Logo(white with orange).svg';
import navicon from './assets/navicon.svg';
import closeicon from './assets/closeicon.svg';

const Sidenav = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = (path) => location.pathname === path;

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div className="flex flex-col md:flex-row">
      {/* Mobile Navbar */}
      <div className="md:hidden bg-[#FF6600] flex justify-between items-center p-4 shadow-md">
        <img src={whitewithorange} alt="Logo" className="w-14 h-14" />
        <button onClick={toggleMobileMenu} className="focus:outline-none">
          <img src={isMobileMenuOpen ? closeicon : navicon} alt="Menu" className="w-8 h-8" />
        </button>
      </div>

      {/* Sidebar for desktop and mobile dropdown menu */}
      <div
        className={`fixed md:relative top-0 left-0 h-full bg-white pt-5 shadow-lg transition-all duration-300 group flex-none w-60  md:w-24 md:hover:w-72 z-50 
          ${isMobileMenuOpen ? 'block' : 'hidden'} md:block`}
      >
         <img src={sidenavllogo} alt="Logo" className="w-14 h-14 block md:hidden mx-auto" />
        <div className="p-6">
          {/* Logo for desktop */}
          <Link
            to="/"
            className="hidden md:flex items-center mb-16 group-hover:justify-start justify-center transition-all duration-300"
          >
            <img src={sidenavllogo} alt="Logo" className="w-14 h-14" />
            <span className="ml-4 font-semibold text-xl hidden group-hover:inline">
              AutoBillsPro
            </span>
          </Link>

          {/* Links */}
          {[
            {
              to: '/dashboard',
              icon: dashboardicon,
              activeIcon: dashboardactiveicon,
              label: 'Dashboard',
            },
            {
              to: '/create-bill',
              icon: createbillicon,
              activeIcon: createbillactiveicon,
              label: 'Create a Bill',
            },
            {
              to: '/card',
              icon: cardinactive,
              activeIcon: cardactive,
              label: 'Cards',
            },
            {
              to: '/bill-payment',
              icon: billicon,
              activeIcon: billactiveicon,
              label: 'Bill Payments',
            },
            {
              to: '/profile',
              icon: profileicon,
              activeIcon: profileactiveicon,
              label: 'Profile',
            },
            {
              to: '/security',
              icon: settingsicon,
              activeIcon: settingsactiveicon,
              label: 'Security',
            },
            {
              to: '/signup',
              icon: logouticon,
              activeIcon: logouticon,
              label: 'Logout',
            },
          ].map((item) => (
            <Link
              key={item.to}
              to={item.to}
              className="flex items-center mb-12 transition-all duration-300 group-hover:justify-start "
            >
              <img
                src={isActive(item.to) ? item.activeIcon : item.icon}
                alt={item.label}
                className="w-7 h-7"
              />
              <span
                className={`ml-4 text-base font-bold block md:hidden group-hover:inline ${
                  isActive(item.to) ? 'text-[#FF6600]' : 'text-black hover:text-[#FF6600]'
                }`}
              >
                {item.label}
              </span>
            </Link>
          ))}
        </div>
      </div>

      {/* Main Content Placeholder */}
      <div className="flex-grow">{/* Content goes here */}</div>
    </div>
  );
};

export default Sidenav;
