import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';

import { useState } from 'react';


const Payinstantlyairtime = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };
  
    const [networkProvider, setNetworkProvider] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [amount, setAmount] = useState("");
  
    // Airtime network providers
    const networkProviders = ["MTN", "Airtel", "Glo", "9mobile"];
  
    const handlePayment = () => {
      // Handle the payment logic here
      console.log("Airtime payment initiated");
    };
  
    return (
      <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-[70rem] flex flex-col items-center">
        {/* Back Button */}
        <div className='w-full lg:w-[1057px]  mx-auto'> 
        <button
          onClick={handleBackClick}
          className="flex items-center w-16 h-16 md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
        </div>
  
  
  
        <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
      <div className="flex justify-between items-center mb-6">
        <p className="font-bold text-[24px] md:text-[32px]">Airtime Recharge</p>
      </div>

      {/* Network Provider Selection */}
      <select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
        onChange={(e) => setNetworkProvider(e.target.value)}
        value={networkProvider}
      >
        <option value="" disabled>Select network provider</option>
        {networkProviders.map((provider, index) => (
          <option key={index} value={provider}>{provider}</option>
        ))}
      </select>

      {/* Phone Number Input */}
      <div className="mb-6">
        <input
          type="text"
          className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem]"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      {/* Amount Input */}
      <div className="relative mb-6">
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">₦</span>
        <input
          type="number"
          className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] pl-10"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

      {/* Pay Now Button */}
      <div className="mt-8">
        <button
          onClick={handlePayment}
          className="w-full bg-[#FF6600] text-white py-3 rounded-lg font-semibold text-lg"
        >
          Pay Now
        </button>
      </div>
    </div>
      </div>
    );
  };

export default Payinstantlyairtime
