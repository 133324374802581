import React, { useState } from 'react';
import Sidenav from './sidenav';
import profilepicture from './assets/profilepicture.png';

const Profile = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: 'Clinton',
    lastName: 'Clinton',
    email: 'Clintonjames@gmail.com',
    state: 'Imo',
    phoneNumber: '+2341234567890',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  return (
    <div className="md:flex md:bg-white bg-[#E0ECF5] h-full lg:h-[60rem]">
      <Sidenav />
      <div className="bg-[#E0ECF5] w-full p-[2rem] h-full">
        <div className="mx-auto w-auto">
          <div className="flex justify-center items-center w-[227px] h-[70px] bg-white rounded-full">
            <p className="text-center font-bold text-[30px]">Profile</p>
          </div>
          <br />
        </div>

        <div className="m-auto w-full max-w-[1190px] h-auto bg-white p-4 md:p-[2rem] rounded-[20px]">
          <br />
          <div>
            <p className="font-semibold text-[18px] opacity-50 mb-5">Profile picture</p>
            <img
              src={profilepicture}
              alt="Profile"
              className="w-24 h-24 md:w-32 md:h-32 rounded-full mx-auto md:mx-0"
            />
            <br />
            <br />
            <div className="flex flex-col md:flex-row md:gap-24 gap-8">
              {/* Profile Info Column 1 */}
              <div className="flex-1">
                <p className="font-semibold text-[16px] md:text-[18px] opacity-50">First Name</p>
                <p className="font-bold text-[20px] md:text-[22px]">{profileData.firstName}</p>
                <br />
                <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Last Name</p>
                <p className="font-bold text-[20px] md:text-[22px]">{profileData.lastName}</p>
                <br />
                <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Email</p>
                <p className="font-bold text-[20px] md:text-[22px]">{profileData.email}</p>
                <br />
                <p className="font-semibold text-[16px] md:text-[18px] opacity-50">State</p>
                <p className="font-bold text-[20px] md:text-[22px]">{profileData.state}</p>
                <br />
              </div>
              {/* Profile Info Column 2 */}
              <div className="flex-1">
                <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Phone number</p>
                <p className="font-bold text-[20px] md:text-[22px]">{profileData.phoneNumber}</p>
                <br />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className="bg-[#FF6600] text-white text-[1.5rem] px-4 py-2 rounded-[10px] font-bold"
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit
            </button>
          
          </div>
        </div>

        <br />
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[90%] max-w-[500px]">
            <h2 className="text-[24px] font-bold mb-4">Edit Profile</h2>
            <form>
              <label className="block mb-2 font-semibold">First Name</label>
              <input
                type="text"
                name="firstName"
                value={profileData.firstName}
                onChange={handleInputChange}
                className="border p-2 rounded w-full mb-4"
              />
              <label className="block mb-2 font-semibold">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={profileData.lastName}
                onChange={handleInputChange}
                className="border p-2 rounded w-full mb-4"
              />
              <label className="block mb-2 font-semibold">Email</label>
              <input
                type="text"
                value={profileData.email}
                disabled
                className="border p-2 rounded w-full mb-4 bg-gray-100"
              />
              <label className="block mb-2 font-semibold">State</label>
              <input
                type="text"
                name="state"
                value={profileData.state}
                onChange={handleInputChange}
                className="border p-2 rounded w-full mb-4"
              />
              <label className="block mb-2 font-semibold">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={profileData.phoneNumber}
                onChange={handleInputChange}
                className="border p-2 rounded w-full mb-4"
              />
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="bg-gray-300 text-black px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="bg-[#FF6600] text-white px-4 py-2 rounded"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
