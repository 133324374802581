import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'

import { Link } from 'react-router-dom'
const Faq = () => {
  return (
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
      <Mainnav />

      <div>
        <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
        Frequently Asked
        Questions
        </h1>
        <br />
     
        <p className="w-full max-w-[711px] h-auto text-[16px] sm:text-[18px] lg:text-[20px] text-center mx-auto text-opacity-70">
        Here some of the most frequently asked questions by our
        users and also genuine answers
        </p>
      </div>


      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="bg-[#FF6600] w-full text-white py-4 lg:py-16 px-1 gap-10">
        {/* 1 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
             <div className='w-full md:w-[480px] mx-auto'>
               
                <h1 className='text-[40px] font-bold' >What does it
                mean to automate a bill? </h1>
                <p className='text-[22px] opacity-80 font-bold'>Instead of always logging in to manually pay a bill, you can just automate it so that it justs automatically pays or renews a bill. </p>
             </div>

             <div className='w-full md:w-[480px] mx-auto'>
               
                <h1 className='text-[40px] font-bold' >How to fund my wallet?</h1>
                <p className='text-[22px] opacity-80 font-bold'>Instead of always logging in to manually pay a bill, you can just automate it so that it justs automatically pays or renews a bill. </p>
             </div>

          
        </div>

        {/* 2 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
    
             <div className='w-full md:w-[480px] mx-auto'>
              
                <h1 className='text-[40px] font-bold' >How does the cards work?</h1>
                <p className='text-[22px] opacity-80 font-bold'>Instead of always logging in to manually pay a bill, you can just automate it so that it justs automatically pays or renews a bill. </p>
             </div>
           
             <div className='w-full md:w-[480px] mx-auto'>
              
                <h1 className='text-[40px] font-bold' >How to automate a bill?</h1>
                <p className='text-[22px] opacity-80 font-bold'>Instead of always logging in to manually pay a bill, you can just automate it so that it justs automatically pays or renews a bill. </p>
             </div>
           
        </div>

        {/* 3 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
             <div className='w-full md:w-[480px] mx-auto'>
               
                <h1 className='text-[40px] font-bold' >Is AutoBillsPro safe?</h1>
                <p className='text-[22px] opacity-80 font-bold'>Yes it is.</p>
             </div>
             <div className='w-full md:w-[480px] mx-auto'>
                
                <h1 className='text-[40px] font-bold' >What happens when there’s
                insufficient funds?</h1>
                <p className='text-[22px] opacity-80 font-bold'>The bill won’t be paid and you will even be notified regularly if your wallet can not handle the upcoming bills.</p>
             </div>
             
        </div>

        <br />
        <p className='text-[22px] font-bold text-center' >If you have more questions? Click below</p>
<br />

<Link to="/contact-us" className='flex justify-center items-center'><button className="bg-[white] w-[209px] h-[54px] font-extrabold text-[20px] text-[#FF6600] text-center  rounded-[25px] mt-4">
Contact Us
            </button></Link>
      </div>

      

      <Footer />
    </div>
  )
}

export default Faq
