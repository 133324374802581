import React from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import dashboardimg from './assets/dashboardimg.png'
import easysignupicon from './assets/easysignup.svg'
import reliableicon from './assets/reliableicon.svg'
import friendlyinterphase from './assets/frirndlyinterphase.svg'
import simpletrackrecordicon from './assets/simpletrackrecordicon.svg'
import automationofdbill from './assets/automationofbillsicon.svg'
import safeandsecureicon from './assets/safeandsecureicon.svg'
import howtogrtstarted from './assets/macbook-pro-template-being-used-next-to-a-water-bottle-a19519 1.png'
import Testimonials from './testimonials';
import Footer from './footer';
import Mainnav from './mainnav';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="bg-[#FF6600] bg-opacity-10 h-full">
      <Mainnav />
      <br />
      <div>
        <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
          Enjoy the Ease of Paying Bills
        </h1>
        <br />
        <br />
        <p className="w-full max-w-[811px] h-auto text-[16px] sm:text-[18px] lg:text-[20px] text-center mx-auto text-opacity-70">
          No need to always interact with your mobile app or website to make a bill payment
          when you can easily just automate a bill with AutoBillsPro.
        </p>
        <br />
        <br />
        <div className="flex flex-wrap justify-center gap-6">
          <Link to="/signup">
          <button className="w-[140px] sm:w-[180px] lg:w-[202px] h-[50px] sm:h-[65px] lg:h-[77px] text-[18px] sm:text-[25px] lg:text-[30px] font-bold text-white rounded-[18px]  bg-[#FF6600] hover:bg-white hover:border-[#FF6600] border-[3px] hover:text-[#FF6600] duration-500">
            Sign up
          </button>
          </Link>
          <Link to="/login">
          <button className="w-[140px] sm:w-[180px] lg:w-[202px] h-[50px] sm:h-[65px] lg:h-[77px] text-[18px] sm:text-[25px] lg:text-[30px] font-bold text-white rounded-[18px]  bg-[#FF6600] hover:bg-white hover:border-[#FF6600] border-[3px] hover:text-[#FF6600] duration-500">
            Log in
          </button>
          </Link>
        </div>
      </div>
      <br />
      <br />
      <div>
        <img src={dashboardimg} alt="" className="mx-auto w-full max-w-[90%] lg:max-w-[70%]" />
      </div>
      <br />
      <br />
      <br />
      <div className="flex flex-wrap justify-evenly mx-auto px-4 gap-8">
        {[
          { img: easysignupicon, title: "Easy Sign up/Log in", text: "AutoBillsPro sign up and log in is really quick and easy as it only makes use of email or phone number." },
          { img: friendlyinterphase, title: "User Friendly Interface", text: "AutoBillsPro user interface is the least complicated or complex as it is easy to use." },
          { img: safeandsecureicon, title: "Safe and Secure", text: "Your data and transactions are kept safe and secure with top-notch security protocols." },
          { img: automationofdbill, title: "Automation of Bills", text: "Automate bill payments effortlessly with just a few clicks." },
          { img: simpletrackrecordicon, title: "Simple Track Record", text: "Track all your payments with a simple and user-friendly system." },
          { img: reliableicon, title: "Reliable Service", text: "Count on AutoBillsPro for uninterrupted and reliable service." },
        ].map((item, idx) => (
          <div
            key={idx}
            className="w-full max-w-[350px] h-auto border-[#FF6600] border-[3px] rounded-3xl p-6 text-center"
          >
            <img src={item.img} alt="" className="m-auto h-[200px] sm:h-[220px] lg:h-[266px]" />
            <h1 className="text-[20px] sm:text-[22px] lg:text-[26px] text-[#FF6600] mt-4">{item.title}</h1>
            <p className="text-[14px] sm:text-[16px]">{item.text}</p>
          </div>
        ))}
      </div>
      <br />
      <br />
      <div className="bg-black w-full text-white py-16 px-12">
        <h1 className="font-bold text-center text-[30px] sm:text-[40px] lg:text-[50px]">
          How to Get Started in Three Simple Steps
        </h1>
        <br />
        <div className="flex flex-wrap justify-center gap-8 lg:gap-16">
          <img src={howtogrtstarted} alt="" className="w-full max-w-[300px] sm:max-w-[400px] lg:max-w-[500px]"/>
          <div className="w-full max-w-[500px] text-left">
            {[
              { title: "1. Create an account", text: "Creating an account is super easy as it only requires either your phone number or email." },
              { title: "2. Fund your wallet", text: "Fund your wallet effortlessly and manage your bills with ease." },
              { title: "3. Start automating bills", text: "Schedule bills to be paid at your convenience—daily, weekly, monthly, or annually." },
            ].map((item, idx) => (
              <div key={idx} className="mb-8">
                <h1 className="font-medium text-[28px] sm:text-[34px] lg:text-[40px]">{item.title}</h1>
                <p className="text-[16px] sm:text-[18px]">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="px-4 text-center">
        <h1 className="text-[30px] sm:text-[40px] lg:text-[50px]">
          Customer <span className="text-[#FF6600]">Testimonials</span>
        </h1>
        <br />
        <Testimonials />
      </div>
      <div className="bg-[#FF6600] py-16 text-center">
        <h1 className="text-[30px] sm:text-[40px] lg:text-[50px] font-bold text-white">
          Start Automating your Bills with AutoBillsPro
        </h1>
        <br />
        <Link to="/signup">
        <button className="bg-white w-[120px] sm:w-[150px] lg:w-[170px] h-[40px] sm:h-[50px] lg:h-[54px] font-extrabold text-[16px] sm:text-[18px] lg:text-[20px] text-[#FF6600] rounded-[25px] hover:bg-[#FF6600] hover:border-[white] border-[3px] hover:text-[white] duration-500">
          Get started
        </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};


export default Home



