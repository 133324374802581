

import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import closedeye from './assets/closedeyeicon.svg';
import openeye from './assets/openeye.svg'; // Replace with the correct path

import logo1 from './assets/AutoBillsPro Logo(Original) 1.png';



const Forgotpasswordotp = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  

  return (
    <div className="mx-5 md:mx-[2rem] ">


    <button
          onClick={handleBackClick}
          className="flex items-center w-[70px] h-[60px] md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
  
  
  
  
      {/* Right Side Form */}
      <div className="flex flex-col mx-auto justify-center items-center w-full lg:w-1/2 p-6 lg:p-12">
        <img src={logo1} alt="Logo" className="mx-auto mb-6" />
  
  
        <h1 className='text-[36px]'>
  Forgot Password
  </h1>
  
        {/* Toggle between Email and Phone Login */}
       
  <br />
        {/*  */}
        <div className="w-full ">
        <p className='text-[20px] mx-auto font-medium lg:w-[488px]'>
        Enter the OTP code either sent to your email
        or phone number to reset your password
        </p>
        </div>
  
      <br />
   {/* input filds  */}
   <div className="w-full max-w-md">
      {/* New Password Input */}
      <div className="relative mt-4">
        <input
          type={passwordVisible ? 'text' : 'password'}
          placeholder="New password"
          className="block border-b-2 border-black w-full px-2 py-2"
        />
        <img
          src={passwordVisible ? openeye : closedeye}
          alt="Toggle visibility"
          className="absolute right-2 top-2 cursor-pointer w-6 h-6"
          onClick={() => setPasswordVisible(!passwordVisible)}
        />
      </div>

      {/* Confirm Password Input */}
      <div className="relative mt-4">
        <input
          type={confirmPasswordVisible ? 'text' : 'password'}
          placeholder="Confirm new password"
          className="block border-b-2 border-black w-full px-2 py-2"
        />
        <img
          src={confirmPasswordVisible ? openeye : closedeye}
          alt="Toggle visibility"
          className="absolute right-2 top-2 cursor-pointer w-6 h-6"
          onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
        />
      </div>
    </div>


<br />

        {/* Login Button */}
        <Link to="/login " className="w-full max-w-md  mt-6">
        <div className='flex justify-center'>
          <button className="bg-[#FF6600] w-full md:w-[374px] h-[62px] rounded-full text-white font-bold">
          Continue
          </button>
          </div>
        </Link>
  
      </div>
    </div>
  )
}

export default Forgotpasswordotp



