import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';

import { useState } from 'react';


const Payinstantlyelectricity = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };
  
    const [provider, setProvider] = useState("");
    const [meterNumber, setMeterNumber] = useState("");
    const [amount, setAmount] = useState("");
    const [serviceProvider, setServiceProvider] = useState("");
  
    // Providers for Prepaid Meter Recharge and Postpaid Bill Settlement
    const electricityProviders = [
      "PHED (Port Harcourt Electricity Distribution Company)",
      "AEDC (Abuja Electricity Distribution Company)",
      "Ikeja Electric",
      "EEDC (Enugu Electricity Distribution Company)",
      "JED (Jos Electricity Distribution Company)",
      "KEDCO (Kano Electricity Distribution Company)",
      "BEDC (Benin Electricity Distribution Company)",
      "IBEDC (Ibadan Electricity Distribution Company)",
    ];
  
    // Form fields required for each provider
    const formFields = {
      "Prepaid Meter Recharge": [
        {
          label: "Meter Number",
          value: meterNumber,
          setter: setMeterNumber,
        },
        {
          label: "Amount to be recharged",
          value: amount,
          setter: setAmount,
        },
        {
          label: "Service Provider",
          value: serviceProvider,
          setter: setServiceProvider,
        },
      ],
      "Postpaid Bill Settlement": [
        {
          label: "Account Number",
          value: meterNumber,
          setter: setMeterNumber,
        },
        {
          label: "Amount to be paid",
          value: amount,
          setter: setAmount,
        },
        {
          label: "Service Provider",
          value: serviceProvider,
          setter: setServiceProvider,
        },
      ],
    };

    const handlePayment = () => {
      // Handle the payment logic here
      console.log("Payment Initiated");
    };
  
    return (
      <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-[70rem] flex flex-col items-center">
        {/* Back Button */}
        <div className='w-full lg:w-[1057px]  mx-auto'> 
        <button
          onClick={handleBackClick}
          className="flex items-center w-16 h-16 md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
        </div>
  
  
  
        <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
      <div className="flex justify-between items-center mb-6">
        <p className="font-bold text-[24px] md:text-[32px]">Electricity Bill Payments</p>
      </div>

      {/* Provider Selection */}
      <select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
        onChange={(e) => setProvider(e.target.value)}
        value={provider}
      >
        <option value="" disabled>Select service provider</option>
        {electricityProviders.map((provider, index) => (
          <option key={index} value={provider}>{provider}</option>
        ))}
      </select>

      {/* Form Type Selection */}
      <select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
        onChange={(e) => setProvider(e.target.value)}
        value={provider}
      >
        <option value="" disabled>Select bill type</option>
        <option value="Prepaid Meter Recharge">Prepaid Meter Recharge</option>
        <option value="Postpaid Bill Settlement">Postpaid Bill Settlement</option>
      </select>

      {/* Form Fields Based on Selection */}
      {formFields[provider] && formFields[provider].map((field, index) => (
        <div key={index} className="mb-6">
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem]"
            placeholder={field.label}
            value={field.value}
            onChange={(e) => field.setter(e.target.value)}
          />
        </div>
      ))}

      {/* Amount Input */}
      <div className="relative mb-6">
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">₦</span>
        <input
          type="number"
          className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] pl-10"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

        {/* Pay Now Button */}
        <div className="mt-8">
        <button
          onClick={handlePayment}
          className="w-full bg-[#FF6600] text-white py-3 rounded-lg font-semibold text-lg"
        >
          Pay Now
        </button>
    </div>
    </div>
      </div>
    );
  };

export default Payinstantlyelectricity
