

import React, { useState } from 'react';

import Sidenav from './sidenav';
import plusicon from './assets/plusicon.svg';
import walleticon from './assets/walleticon.svg';
import leftarrow from './assets/arrowicon.png';
import wifiicon from './assets/wifiicon.png';
import cardqrcode from './assets/Frame 74.png';
import whitewithorange from './assets/AutoBillsPro Logo(white with orange).png';
import blackandwhitelogo from './assets/AutoBillsPro Icon(white with black) 1.png';

const Card = () => {
  const [isPopup1Open, setIsPopup1Open] = useState(false);
  const [isPopup2Open, setIsPopup2Open] = useState(false);

  const togglePopup1 = () => {
    setIsPopup1Open(!isPopup1Open);
  };

  const togglePopup2 = () => {
    setIsPopup2Open(!isPopup2Open);
  };

  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
      <Sidenav />
      <div className="bg-[#E0ECF5] w-full min-h-screen p-[1rem] md:p-8">
        {/* Header Section */}
        <div className="md:flex justify-between items-center mb-6">
          <div className="w-full md:w-[260px] h-[70px] bg-white rounded-full text-center p-4 cursor-pointer">
            <p className="text-[24px] md:text-[30px] font-bold">Cards</p>
          </div>
        </div>

        {/* Info Section */}
        <div className="bg-white p-6 rounded-[20px] mb-6">
          <p className="text-[18px] md:text-[20px] font-bold text-center">
          Cards can be used as a payment method on platforms that require a credit card,
           such as Netflix, Spotify, and similar services. They provide a convenient way
            to automate your subscriptions and other online transactions.

          </p>
        </div>

        {/* Main Content */}
        <div className="md:flex justify-between gap-6">
          {/* Left Section */}
          <div className="leftdiv w-full md:w-1/2 space-y-5  ">
            {/* Dollar Card */}
            <div className="w-full h-[223px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">Dollar Card</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">$230.00</span>
              </div>
            </div>

            {/* Add Money / Withdraw Buttons */}
            <div className="md:flex gap-4">
              <button onClick={togglePopup1} className="w-full">
                <div className="flex justify-center items-center w-full h-[64px] bg-white rounded-[20px] mb-4 md:mb-0">
                  <img src={plusicon} alt="Add Money Icon" className="w-[23px] h-[23px]" />
                  <span className="text-[18px] md:text-[24px] text-[#FF6600] mx-2 font-bold">Add Money</span>
                </div>
              </button>
              <button onClick={togglePopup2} className="w-full">
                <div className="flex justify-center items-center w-full h-[64px] bg-white rounded-[20px]">
                  <img src={walleticon} alt="Withdraw Icon" className="w-[23px] h-[23px]" />
                  <span className="text-[18px] md:text-[24px] text-[#FF6600] mx-2 font-bold">Withdraw</span>
                </div>
              </button>
            </div>

            {/* Naira Card */}
            <div className="w-full h-[300px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">Naira Card</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">₦50,830.00</span>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-full md:w-1/2 space-y-6">
            {/* Dollar Card Details */}
            <div className="w-full h-[300px] p-6 text-white bg-black rounded-[20px]">
              <div className="flex justify-between">
                <p className="text-[20px] md:text-[24px] font-bold">Dollar Card Details</p>
                <img src={wifiicon} alt="WiFi Icon" />
              </div>
              <img src={cardqrcode} alt="QR Code" className="mt-6" />
              <p className="text-[24px] md:text-[30px] font-bold">
                <div className="flex justify-between">
                  <span>7563</span> <span>6413</span> <span>0085</span> <span>4374</span>
                </div>
              </p>
              <div className="mt-4 flex justify-between">
                <div className="flex space-x-10">
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                </div>
                <img src={blackandwhitelogo} alt="Logo" className="h-[44px]" />
              </div>
            </div>

            {/* Naira Card Details */}
            <div className="w-full h-[300px] p-6 text-white bg-[#FF6600] rounded-[20px]">
              <div className="flex justify-between">
                <p className="text-[20px] md:text-[24px] font-bold">Naira Card Details</p>
                <img src={wifiicon} alt="WiFi Icon" />
              </div>
              <img src={cardqrcode} alt="QR Code" className="mt-6" />
              <p className="text-[24px] md:text-[30px] font-bold">
                <div className="flex justify-between">
                  <span>7563</span> <span>6413</span> <span>0085</span> <span>4374</span>
                </div>
              </p>
              <div className="mt-4 flex justify-between">
                <div className="flex space-x-10">
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                </div>
                <img src={whitewithorange} alt="Logo" className="h-[44px]" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopup1Open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white  w-[90%] rounded-[20px] p-[1rem] md:p-[3rem] shadow-lg">
            <button onClick={togglePopup1} className="">
              <img src={leftarrow} alt="Back Icon" />
            </button>
            <br />
            {/* cards */}
            <br />
            <div className='lg:flex justify-evenly lg:justify-between mx-[3rem] md:mx-0'>

            <div className="shadow w-full md:w-[433px] lg:w-[600px] h-[231px]  p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">My Wallet</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">₦205,830.00</span>
              </div>
            </div>
{/* 2 */}
<br />
<br />
            <div className="shadow w-full md:w-[433px] lg:w-[600px] h-[231px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">Dollar Card</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">$230.00</span>
              </div>
            </div>

            </div>
            <br />
            <br />
            <p className="text-[22px] mb-4">Your wallet balance is used to fund your dollar card.</p>
            <p className="text-[22px] font-bold mb-2">Amount to fund in dollars</p>
            <input
              type="text"
              className="border-b-2 p-4 text-[22px] w-full lg:w-[20rem] mb-4"
              placeholder="Amount($)"
            />
            <p className="text-[22px] font-bold mb-2">Equivalent in Naira</p>
            <input
              type="text"
              className="border-b-2 p-4 text-[22px] w-full lg:w-[20rem] mb-4"
              placeholder="0.00"
            />
            <div className="flex justify-end">
              <button className="bg-[#FF6600] text-white text-[32px] font-bold w-[250px] h-[69px] rounded-[20px]">
                Fund Card
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup 2 */}
      {isPopup2Open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[90%] rounded-[20px] p-[1rem] md:p-[3rem] shadow-lg">
            <button onClick={togglePopup2} className="">
              <img src={leftarrow} alt="Back Icon" />
            </button>
            <br />
            {/* cards */}
            <br />
            <div className='lg:flex justify-evenly lg:justify-between mx-[3rem] md:mx-0'>

            <div className="shadow w-full md:w-[433px] lg:w-[600px] h-[231px]  p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">My Wallet</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">₦205,830.00</span>
              </div>
            </div>
{/* 2 */}
<br />
<br />
            <div className="shadow w-full md:w-[433px] lg:w-[600px] h-[231px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">Naira Card</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">₦50,830.00</span>
              </div>
            </div>

            </div>
            <br />
            <br />
            <p className="text-[22px] mb-4">Your wallet balance is used to fund your naira card.</p>
            <p className="text-[22px] font-bold mb-2">Amount to fund in naira</p>
            <input
              type="text"
              className="border-b-2 p-4 text-[22px] w-full md:w-[20rem] mb-4"
              placeholder="Amount"
            />
            <div className="flex justify-end">
              <button className="bg-[#FF6600] text-white text-[32px] font-bold w-[250px] h-[69px] rounded-[20px]">
              Fund card
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;

