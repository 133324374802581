import React from 'react'
import Sidenav from './sidenav'
import { Link } from 'react-router-dom';

import electricityicon from './assets/electricityicon.svg'
import tvicon from './assets/tvicon.svg'
import dataicon from './assets/daataicon.svg'
import phoneicon from './assets/phoneicon.svg'

const BillPayment = () => {
  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]  h-full'>
    <Sidenav/>
<div className='bg-[#E0ECF5]  w-full p-[1rem] md:p-[3rem] h-full md:h-[60rem]'>

<div className='md:flex justify-between'>
    <div className='w-full md:w-[260px] h-[70] mb-[1rem] bg-white rounded-full text-center p-3 cursor-pointer'>
        <p className='text-[30px] font-bold'>Bill payments</p>
        </div>
        <br />
        <Link to="/create-bill">
        <div className='w-full md:w-[260px]  h-[70] bg-white rounded-full text-center p-3'>
        <p className='text-[30px] font-bold text-[#FF6600]'>Create a bill</p>
        </div></Link>
</div>



<br />

<div class="overflow-x-auto bg-white p-[2rem] rounded-[20px]">


  <div className='md:flex w-full'>
  
      
   <Link to="/bill-payment" className='w-full'><div className='border-b-[2px] border-[#FF6600] w-full'>
    <p className=' text-[24px] font-bold text-center text-[#FF6600] p-3'>
    Bill Payments
        </p>
    </div></Link> 
      <br />
    <Link to="/bill-payments-history" className='w-full'> <div className='border-b-[2px] border-black opacity-40 w-full'>
    <p className=' text-[24px] font-bold text-center opacity-40 p-3'>
    Bill Payments history
        </p>
    </div></Link>
      
  </div>

  <br />

  <div class="relative inline-block w-[94px] h-[30px]">
  <select
    class="block w-[8rem] bg-white border border-black text-black font-medium p-3   rounded">
    <option>This Month</option>
    <option>Last Month </option>
    <option>This Year</option>
    <option> All time</option>
  </select>
  <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-gray-700">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      
    </svg>
  </div>
</div>

<br />
<br />

  <table class="min-w-full text-left border-collapse ">
    <thead>
      <tr>
        <th class="py-4 px-4  text-[11px] md:text-[16px] opacity-70">#Title</th>
        <th class="py-4 px-4  text-[11px] md:text-[16px] opacity-70">#Details</th>
        <th class="py-4 px-4  text-[11px] md:text-[16px] opacity-70">#Due Date</th>
        <th class="py-4 px-4  text-[11px] md:text-[16px] opacity-70">#Amount</th>
        <th class="py-4 px-4  text-[11px] md:text-[16px] opacity-70">#Status</th>
      </tr>
    </thead>
    <tbody>

    

      <br />


{/* 3 */}
      <tr class="font-bold">
      <td class="py-2 px-4 text-[9px] md:text-[18px]">  <div class="flex items-center">
        <img src={tvicon} alt="" className='mr-2 w-[31px] h-[30px] '/>
         
          </div></td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">DSTV</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">31st Oct - 12:30pm</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">₦21,000.00</td>
        <button className="p-2 text-[9px] md:text-[18px]  bg-[#FFCC00] text-white rounded-sm">Pending</button>
        <td class="py-2 px-4 text-[9px] md:text-[18px] underline text-[#FF6600]">View</td>
      </tr>

<br />

{/* 4 */}
      <tr class="font-bold">
      <td class="py-2 px-4 text-[9px] md:text-[18px]">  <div class="flex items-center">
        <img src={electricityicon} alt="" className='mr-2 w-[31px] h-[30px]'/>
            
          </div></td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">Ikeja Elect...</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">21st Oct - 4:30pm</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">₦10,000.00</td>
        <button className="p-2 text-[9px] md:text-[18px]  bg-[#FFCC00] text-white rounded-sm">Pending</button>
        <td class="py-2 px-4 text-[9px] md:text-[18px] underline text-[#FF6600]">View</td>
      </tr>

<br />

{/* 5 */}
<tr class="font-bold">
      <td class="py-2 px-4 text-[9px] md:text-[18px]">  <div class="flex items-center">
        <img src={dataicon} alt="" className='mr-2 w-[31px] h-[30px]'/>
            
          </div></td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">MTN</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">1st Nov - 10:30pm</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">₦8,000.00</td>
        <button className="p-2 text-[9px] md:text-[18px]  bg-[#FFCC00] text-white rounded-sm">Pending</button>
        <td class="py-2 px-4 text-[9px] md:text-[18px] underline text-[#FF6600]">View</td>
      </tr>

     <br />

{/* 6 */}
       <tr class="font-bold">
      <td class="py-2 px-4 text-[9px] md:text-[18px]">  <div class="flex items-center">
        <img src={phoneicon} alt="" className='mr-2 w-[31px] h-[30px]'/>
           
          </div></td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">Airtel</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">2nd Nov - 10:30pm</td>
        <td class="py-2 px-4 text-[9px] md:text-[18px]">₦5,000.00</td>
        <button className="p-2 text-[9px] md:text-[18px]  bg-[#FFCC00] text-white rounded-sm">Pending</button>
        <td class="py-2 px-4 text-[9px] md:text-[18px] underline text-[#FF6600]">View</td>
      </tr>


     
    </tbody>
  </table>
</div>

</div>
  </div>
  )
}

export default BillPayment
