import React from 'react'
import { useState } from 'react';
import closedeyeicon from './assets/closedeyeicon.png';
import Sidenav from './sidenav'
import { Link } from 'react-router-dom';
import qrcode from './assets/qrcode.png'
const Secureaccount = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };
  
  return (
<div className='md:flex md:bg-white bg-[#E0ECF5]'>
    <Sidenav/>
<div className='bg-[#E0ECF5]  w-full p-[3rem] '>

<div className='md:flex justify-between'>
       
       <div className='w-full md:w-[260px] mb-5 h-[70px] bg-white rounded-full text-center p-3'>
         <p className='text-[30px] font-bold'>security</p>
       </div>
<Link to="/profile">
       <div className='w-full md:w-[260px] h-[70px] bg-[#FF6600] rounded-full text-center p-3'>
         <p className='text-[30px] text-white font-bold'>Profile</p>
       </div>
</Link>
     </div>

<br />

<div className="overflow-x-auto bg-white p-4 md:p-[2rem] rounded-[20px]">
  <div className="flex flex-col md:flex-row w-full">
    {/* Change Password Link */}
    <Link to="/security" className="w-full">
      <div className="border-b-[2px] border-black opacity-40">
        <p className="text-center text-[18px] md:text-[24px] font-bold opacity-40 p-3">Change password</p>
      </div>
    </Link>
    <br />
    {/* Secure Account Link */}
    <Link to="/secureaccount" className="w-full">
      <div className="border-b-[2px] border-[#FF6600]">
        <p className="text-center text-[18px] md:text-[24px] font-bold text-[#FF6600] p-3">Secure account</p>
      </div>
    </Link>
  </div>

  <br />
  <div className="w-full h-auto md:h-[30rem]">
    <p className="font-bold text-[22px] md:text-[28px]">Two-factor authentication</p>
    <p className="text-[16px] md:text-[18px]">Download an authentication app to perform either of the following:</p>
    <br />

    <div className="flex flex-col md:flex-row items-center md:gap-6">
      <div className="text-center">
        <img src={qrcode} alt="QR Code" className="w-24 h-24 md:w-32 md:h-32 mx-auto" />
        <p className="text-[16px] md:text-[18px] mt-2">Scan the QR code</p>
      </div>

      <p className="my-4 md:my-auto font-bold text-center md:text-left">OR</p>

      <div className="text-center w-full md:w-[30rem] my-4 md:my-auto">
        <p className="font-semibold text-[18px] md:text-[23px] break-words">RGSB56 YGUYSE7 DGV7 78DT8E7 FAUYFWE6 6Y89EVS9 8EYA7E</p>
        <br />
        <p className="text-[16px] md:text-[18px]">Copy Barcode and paste in the app</p>
      </div>
    </div>
    <br />

    <p className="text-[16px] md:text-[18px] w-full md:w-[600px]">
      After scanning the QR code or inputting the barcode, a six-digit pin will be shown which should be entered below for confirmation.
    </p>

    {/* Pin Input */}
    <div className="relative w-full max-w-[214px] my-4">
      <input
        type={showPassword ? 'text' : 'password'}
        className="border-b-2 border-black border-opacity-40 w-full p-3"
        placeholder="Pin"
      />
      <img
        src={closedeyeicon}
        alt="Toggle password visibility"
        onClick={togglePasswordVisibility}
        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-[18px] h-[7px]"
      />
    </div>

    {/* Confirm Button */}
    <div className="flex justify-center md:justify-end mt-4 md:mt-[2rem]">
      <button className="bg-[#FF6600] w-full md:w-[273px] h-[50px] md:h-[69px] rounded-[10px] md:rounded-[20px] text-[24px] md:text-[40px] font-bold text-white">
        Confirm
      </button>
    </div>
  </div>
</div>

</div>
  </div>
  )
}


export default Secureaccount
