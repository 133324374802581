
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import logo1 from './assets/AutoBillsPro Logo(Original) 1.png';


const Forgotpasswordphonenumber = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };

  return (
    <div className="mx-5 md:mx-[2rem] ">


    <button
          onClick={handleBackClick}
          className="flex items-center w-[70px] h-[60px] md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
  
  
  
  
  
      {/* Right Side Form */}
      <div className="flex flex-col mx-auto justify-center items-center w-full lg:w-1/2 p-6 lg:p-12">
        <img src={logo1} alt="Logo" className="mx-auto mb-6" />
  
  
        <h1 className='text-[36px]'>
  Forgot Password
  </h1>
  
        {/* Toggle between Email and Phone Login */}
        <div className="flex mt-5 mb-6 w-full max-w-md">
          <Link
            to="/forgot-password-email"
            className="bg-[#E8E8E8] px-8 py-2 text-center rounded-s-full text-black opacity-50 font-bold w-1/2"
          >
            Email
          </Link>
          <Link
            to="/forgot-password-phonenumber"
            className="bg-[#FF6600] px-8 py-2 text-center rounded-e-full   text-white font-bold w-1/2"
          >
            Phone
          </Link>
        </div>
  <br />
        {/*  */}
        <div className="w-full  ">
        <p className='text-[20px] mx-auto font-medium lg:w-[550px]'>
        An OTP code will be sent to <span className='font-bold'>+234 810 546 1664 </span>
        to verify it’s you requesting for a password reset.
        </p>
        </div>
  
      <br />
        {/* Login Button */}
        <Link to="/forgot-password" className="w-full max-w-md  mt-6">
        <div className='flex justify-center'>
          <button className="bg-[#FF6600] w-full md:w-[374px] h-[62px] rounded-full text-white font-bold">
          Proceed
          </button>
          </div>
        </Link>
  
      </div>
    </div>
  )
}

export default Forgotpasswordphonenumber



