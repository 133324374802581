import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './signup';
import Login from './login';
import PhoneSignup from './phone-signup'; // Import the new PhoneSignup component
import PhoneLogin from './phone-login'
import Dashboard from './dashboard';
import Settings from './security';
import CreateBill from './create-bill';
import BillPayment from './bill-payment';
import Profile from './profile';
import BillPaymenthistory from './bill-payments-history';
import Automateabill from './automate-bill';
import Payinstantly from './pay-instantly';
import Automateabilltv from './automate-bill-tv';
import Automateabillcustom from './automate-bill-custom';
import Payinstantlytv from './pay-instantly-tv';

import Secureaccount from './secureaccount';
import Withdraw from './withdraw';
import Addmoney from './add-money';
import Banktransfer from './bank-transfer';
import Home from './home';
import Howitworks from './how-it-works';
import Faq from './Frequently-asked-questions';
import Card from './card';
import Termsofservices from './termsofservices';
import Contactus from './contact-Us';
import About from './about';
import Privacypolicy from './privacypolicy';
// import ServicesAutomateabill from './services-automate-bill';
import ServicesAutomateabill from './services-Automate-bill';
import ServicesPayinstantly from './services-Payinstantly';
import ServicesUpcommingbills from './services-Upcoming-bills';
import ServicesBillpayments from './services-Bill-payments-history';
import Automatebillairtime from './automate-bill-airtime';
import Payinstantlyairtime from './pay-instantly-airtime';
import Automatebilldata from './automate-bill-data';
import Automatebillelectricity from './automate-bill-electricity';
import Payinstantlydata from './pay-instantly-data';
import Payinstantlyelectricity from './pay-instantly-electricity';
import Forgotpasswordotp from './forgot-password-otp';
import Forgotpassword from './forgot-password';
import Forgotpasswordemail from './forgot-password-email';
import Forgotpasswordphonenumber from './forgot-password-phonenumber';
import Automatebilleducation from './automate-bill-education';
import Payinstantlyeducation from './pay-instantly-education';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Route to the Signup form */}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        {/* Route to the Login form */}
        <Route path="/login" element={<Login />} />
        {/* Route to the PhoneSignup form */}
        <Route path="/phone-signup" element={<PhoneSignup />} />
        <Route path="/phone-login" element={<PhoneLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/security" element={<Settings />} />
        <Route path="/create-bill" element={<CreateBill />} />
        <Route path="/bill-payment" element={<BillPayment />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/bill-payments-history" element={<BillPaymenthistory />} />
        <Route path="/automate-bill" element={<Automateabill />} />
        <Route path="/pay-instantly" element={<Payinstantly />} />
        <Route path="/automate-bill-tv" element={<Automateabilltv />} />
        <Route path="/automate-bill-electricity" element={<Automatebillelectricity />} />
        <Route path="/automate-bill-education" element={<Automatebilleducation />} />
        <Route path="/automate-bill-data" element={<Automatebilldata />} />
        <Route path="/automate-bill-airtime" element={<Automatebillairtime />} />
        <Route path="/automate-bill-custom" element={<Automateabillcustom />} />
        <Route path="/pay-instantly-tv" element={<Payinstantlytv />} />
        <Route path="/pay-instantly-electricity" element={<Payinstantlyelectricity />} />
        <Route path="/pay-instantly-data" element={<Payinstantlydata />} />
        <Route path="/pay-instantly-education" element={<Payinstantlyeducation />} />
        <Route path="/pay-instantly-airtime" element={<Payinstantlyairtime />} />
       
        <Route path="/profile" element={<Profile />} />
        <Route path="/security" element={<Settings />} />
        <Route path="/secureaccount" element={<Secureaccount />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/add-money" element={<Addmoney />} />
        <Route path="/bank-transfer" element={<Banktransfer />} />
     
        <Route path="/how-it-works" element={<Howitworks />} />
        <Route path="Frequently-asked-questions" element={<Faq />} />
        <Route path="/card" element={<Card />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/termsofservices" element={<Termsofservices />} />
        <Route path="/about" element={<About />} />
        <Route path="/forgot-password-otp" element={<Forgotpasswordotp />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/forgot-password-email" element={<Forgotpasswordemail />} />
        <Route path="/forgot-password-phonenumber" element={<Forgotpasswordphonenumber />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/services-automate-bill" element={<ServicesAutomateabill />} />
        <Route path="/services-payinstantly" element={<ServicesPayinstantly />} />
        <Route path="/services-upcoming-bills" element={<ServicesUpcommingbills />} />
        <Route path="/services-bill-payments-history" element={<ServicesBillpayments />} />

      </Routes>
    </Router>
  );
};

export default App;
