import React, { useState } from 'react';
import Sidenav from './sidenav';
import { Link } from 'react-router-dom';

const BillPaymenthistory = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);

  const billData = [
    { id: 1, month: "January", year: 2024, totalPaid: "₦50,000.00", details: "Paid for DSTV, Electricity, and Internet." },
    { id: 2, month: "February", year: 2024, totalPaid: "₦30,000.00", details: "Paid for Netflix and Mobile Data." },
  ];

  const handleViewClick = (bill) => {
    setSelectedBill(bill);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedBill(null);
  };

  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
      <Sidenav />
      <div className='bg-[#E0ECF5] w-full p-[3rem] h-full md:h-[60rem]'>

        <div className='md:flex justify-between'>
          <div className='w-full md:w-[260px] h-[70] mb-[1rem] md:mb-0 bg-white rounded-full text-center p-3'>
            <p className='text-[30px] font-bold'>Bill payments</p>
          </div>
          <Link to="/create-bill">
            <div className='w-full md:w-[260px] h-[70] bg-white rounded-full text-center p-3'>
              <p className='text-[30px] font-bold text-[#FF6600]'>Create a bill</p>
            </div>
          </Link>
        </div>

        <br />

        <div className="overflow-x-auto bg-white p-[2rem] rounded-[20px]">
          <div className='md:flex w-full'>
            <Link to="/bill-payment" className='w-full'>
              <div className='border-b-[2px] border-black opacity-40'>
                <p className='text-[24px] font-bold text-center opacity-40 p-3'>
                  Bill Payments
                </p>
              </div>
            </Link>

            <Link to="/billpaymentshistory" className='w-full'>
              <div className='border-b-[2px] border-[#FF6600]'>
                <p className='text-[24px] font-bold text-center text-[#FF6600] p-3'>
                  Bill Payments history
                </p>
              </div>
            </Link>
          </div>

          <br />

          <div className="relative inline-block w-[94px] h-[30px]">
            <select className="block w-[9rem] bg-white border border-black text-black font-medium p-3 rounded">
              <option>This Month</option>
              <option>Last Month</option>
              <option>This Year</option>
              <option>All time</option>
            </select>
          </div>

          <br />
          <br />

          <table className="min-w-full text-left">
            <thead>
              <tr>
                <th className="py-3 text-[12px] opacity-70">#</th>
                <th className="py-3 text-[12px] opacity-70">Month</th>
                <th className="py-3 text-[12px] opacity-70">Year</th>
                <th className="py-3 text-[12px] opacity-70">Total Paid</th>
                <th className="py-3 text-[12px] opacity-70">View</th>
              </tr>
            </thead>
            <tbody>
              {billData.map((bill) => (
                <tr key={bill.id} className="font-bold">
                  <td className="py-2 px-4 text-[9px] md:text-[18px]">{bill.id}</td>
                  <td className="py-2 px-4 text-[9px] md:text-[18px]">{bill.month}</td>
                  <td className="py-2 px-4 text-[9px] md:text-[18px]">{bill.year}</td>
                  <td className="py-2 px-4 text-[9px] md:text-[18px]">{bill.totalPaid}</td>
                  <td
                    className="py-2 px-4 text-[9px] md:text-[18px] underline cursor-pointer text-[#FF6600]"
                    onClick={() => handleViewClick(bill)}
                  >
                    View
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-[90%] md:w-[50%] rounded-[20px] p-[2rem] shadow-lg">
              <h2 className="text-[20px] md:text-[24px] font-bold mb-4">
                {selectedBill?.month} {selectedBill?.year} Details
              </h2>
              <p className="text-[16px] md:text-[18px] mb-6">{selectedBill?.details}</p>
              <button
                className="bg-[#FF6600] text-white px-4 py-2 rounded-[10px] font-bold"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default BillPaymenthistory;
