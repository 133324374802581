
import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import tvicon from './assets/tvicon.svg';
import leftarrow from './assets/leftarrow.png';
import rightarrow from './assets/rightarrow.png';
import { useState } from 'react';
import { useEffect } from 'react';




const Automateabilltv = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const [biller, setBiller] = useState("");
  const [packageType, setPackageType] = useState("");

 // Package options for each biller
 const packageOptions = {
  GOTV: ["Max", "Jolli", "Jinja", "Smallie"],
  DSTV: ["Premium", "Compact Plus", "Compact", "Family", "Access", "Yanga", "Padi"],
  Startimes: ["Basic", "Smart", "Classic", "Super"],
};

// Form fields required for each biller
const formFields = {
  DSTV: {
    idPlaceholder: "Enter smartcard number",
    idName: "Smartcard Number",
  },
  GOTV: {
    idPlaceholder: "Enter IUC number",
    idName: "IUC Number",
  },
  Startimes: {
    idPlaceholder: "Enter smartcard number",
    idName: "Smartcard Number",
  },
  }


  

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectionType, setSelectionType] = useState('daily');
  const [selectedDays, setSelectedDays] = useState([]);
  
  useEffect(() => {
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
  
    if (selectionType === 'daily') {
      // Select all days in the current month
      const allDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      setSelectedDays(allDays);
    } else if (selectionType === 'weekly') {
      // Auto-select the same weekdays in the new month
      const targetDayOfWeek = selectedDays[0]
        ? new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            selectedDays[0]
          ).getDay()
        : new Date().getDay(); // Default to current day if none selected
  
      const newSelectedDays = [];
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        if (date.getDay() === targetDayOfWeek) {
          newSelectedDays.push(i);
        }
      }
      setSelectedDays(newSelectedDays);
    } else if (selectionType === 'monthly') {
      // Keep the same day of the month across all months
      const dayOfMonth = selectedDays[0] || 1; // Default to the 1st if no selection
      const newSelectedDays = [];
      for (let month = 0; month < 12; month++) {
        const daysInCurrentMonth = new Date(currentDate.getFullYear(), month + 1, 0).getDate();
        if (dayOfMonth <= daysInCurrentMonth) {
          newSelectedDays.push(`${currentDate.getFullYear()}-${month + 1}-${dayOfMonth}`);
        }
      }
      setSelectedDays(newSelectedDays);
    } else if (selectionType === 'yearly') {
      // Keep the same day and month across all years
      const [ month, day] = (selectedDays[0] || '').split('-').map(Number);
      const currentDay = day || new Date().getDate();
    const currentMonth = month || currentDate.getMonth() + 1;
    // const currentYear = year || currentDate.getFullYear() + 1;

      const newSelectedDays = [];
  
      for (let year = currentDate.getFullYear(); year < currentDate.getFullYear() + 10; year++) {
        const daysInCurrentMonth = new Date(year, currentMonth, 0).getDate();
        if (currentDay <= daysInCurrentMonth) {
          newSelectedDays.push(`${year}-${currentMonth}-${currentDay}`);
        }
      }
      setSelectedDays(newSelectedDays);
    }
  }, [currentDate, selectionType, selectedDays]);
  
  const changeMonth = (direction) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + direction,
      1
    );
    setCurrentDate(newDate);
  };
  
  const handleSelectionChange = (e) => {
    setSelectionType(e.target.value);
  };
  
  const handleDayClick = (day) => {
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
  
    let newSelectedDays = [];
  
    if (selectionType === 'daily') {
      // Select all days in the current month
      newSelectedDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    } else if (selectionType === 'weekly') {
      // Highlight all days in the current month that fall on the same day of the week
      const targetDayOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      ).getDay();
  
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        if (date.getDay() === targetDayOfWeek) {
          newSelectedDays.push(i);
        }
      }
    } else if (selectionType === 'monthly') {
      for (let month = 0; month < 12; month++) {
        const daysInCurrentMonth = new Date(currentDate.getFullYear(), month + 1, 0).getDate();
        if (day <= daysInCurrentMonth) {
          newSelectedDays.push(`${currentDate.getFullYear()}-${month + 1}-${day}`);
        }
      }
    } else if (selectionType === 'yearly') {
      for (let year = currentDate.getFullYear(); year < currentDate.getFullYear() + 10; year++) {
        const daysInCurrentMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate();
        if (day <= daysInCurrentMonth) {
          newSelectedDays.push(`${year}-${currentDate.getMonth() + 1}-${day}`);
        }
      }
    }
  
    setSelectedDays(newSelectedDays);
  };
  
  const renderCalendarDates = () => {
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
  
    const calendarDays = [];
    // Add blank spaces for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="invisible">0</div>);
    }
  
    // Render actual days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const isSelected =
        selectionType === 'daily'
          ? selectedDays.includes(i)
          : selectionType === 'weekly'
          ? selectedDays.includes(i)
          : selectionType === 'monthly'
          ? selectedDays.includes(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${i}`)
          : selectionType === 'yearly'
          ? selectedDays.some((day) => day.endsWith(`-${currentDate.getMonth() + 1}-${i}`))
          : false;
  
      calendarDays.push(
        <div
          key={i}
          onClick={() => handleDayClick(i)}
          className={`p-2 cursor-pointer rounded-full ${
            isSelected ? 'text-[#FF6600] font-bold bg-gray-200' : ''
          }`}
        >
          {i}
        </div>
      );
    }
  
    return calendarDays;
  };
  


  const [hour, setHour] = useState('12');
  const [minute, setMinute] = useState('00');
  const [period, setPeriod] = useState('PM');

  // Toggle between AM and PM
  const togglePeriod = () => {
    setPeriod((prevPeriod) => (prevPeriod === 'AM' ? 'PM' : 'AM'));
  };

  // Hour and minute options
  const hours = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, index) => index.toString().padStart(2, '0'));



  return (
    <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-full">
      {/* Back Button */}
      <button
        onClick={handleBackClick}
        className="flex items-center w-[70px] h-[60px] md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
      >
        <img src={arrowicon} alt="Back" className="mx-auto" />
      </button>

      <div className="card-divs flex flex-col md:flex-row justify-center gap-8">
        {/* Form Section */}
        <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
      <div className="flex justify-between items-center mb-6">
        <p className="font-bold text-[24px] md:text-[32px]">TV</p>
        <img src={tvicon} alt="TV Icon" />
      </div>

      {/* Biller Selection */}
      <select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black text-opacity-40 mb-6"
        onChange={(e) => setBiller(e.target.value)}
        value={biller}
      >
        <option value="" disabled>
          Select biller
        </option>
        <option value="GOTV">GOTV</option>
        <option value="DSTV">DSTV</option>
        <option value="Startimes">Startimes</option>
      </select>

      {biller && (
        <>
          {/* ID Number (Smartcard/IUC) */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder={formFields[biller].idPlaceholder}
          />

          {/* Customer Name */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter customer name"
          />

          {/* Phone Number */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter phone number"
          />

          {/* Package Selection */}
          <select
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black text-opacity-40 mb-6"
            onChange={(e) => setPackageType(e.target.value)}
            value={packageType}
          >
            <option value="" disabled>
              Select a package
            </option>
            {packageOptions[biller].map((pkg, index) => (
              <option key={index} value={pkg}>
                {pkg}
              </option>
            ))}
          </select>

          {/* Amount to be Paid */}
          
          <div className="relative mb-6">
  <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">₦</span>
  <input
    type="number"
    className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] pl-10"
    placeholder="Enter amount to be paid"
  />
</div>



<select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black  mb-6">
        <option value="" >
          Payment Method
        </option>
        <option value="">Wallet </option>
        <option value="">Card</option>
      </select>          

          {/* Comment (Optional) */}
          <p className="font-semibold text-[18px] md:text-[24px] mb-2">Add a comment (Optional)</p>
          <textarea
            className="w-full h-[100px] md:h-[122px] border border-black rounded-[10px] p-3 md:p-4 resize-none mb-6"
            placeholder="Your comment here"
          ></textarea>
        </>
      )}
    </div>

        {/* Calendar and Time Picker Section */}
        <div className="w-full max-w-[600px] flex flex-col items-center bg-white p-6 md:p-[2rem] rounded-[20px]">




        {/* Calendar */}
        <div className="w-full max-w-[541px] bg-white rounded-[20px] p-4 md:p-[2rem]">
      <div className="flex justify-between items-center">
        <p className="font-bold text-[20px] md:text-[30px] text-center md:text-left">
          Set date and time
        </p>
        <select
          value={selectionType}
          onChange={handleSelectionChange}
          className="border border-gray-300 rounded-md p-2 text-sm"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>

      {/* Month and Year Navigation */}
      <div className="flex items-center justify-between mt-4">
        <button onClick={() => changeMonth(-1)} className="text-2xl font-bold">
          <img
            src={leftarrow}
            alt="Previous Month"
            className="w-[18px] h-[19px] md:w-[20.25px] md:h-[21px]"
          />
        </button>
        <span className="text-lg md:text-xl font-semibold">
          {currentDate.toLocaleString('default', { month: 'long' })}{' '}
          {currentDate.getFullYear()}
        </span>
        <button onClick={() => changeMonth(1)} className="text-2xl font-bold">
          <img
            src={rightarrow}
            alt="Next Month"
            className="w-[18px] h-[19px] md:w-[20.25px] md:h-[21px]"
          />
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-1 md:gap-2 mt-4 text-center text-sm md:text-base">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="font-semibold">
            {day}
          </div>
        ))}
        {renderCalendarDates()}
      </div>
    </div>




    
    {/* TimePicker */}



    <div className="w-full max-w-[541px] h-[120px] bg-white rounded-[20px] flex items-center justify-evenly p-2 md:p-4 space-x-4 md:space-x-6">
      
      {/* Hour Dropdown */}
      <select
        value={hour}
        onChange={(e) => setHour(e.target.value)}
        className="text-center text-[16px] md:text-[24px] font-medium border-none focus:outline-none w-1/4 bg-gray-100 p-2 rounded-lg shadow-md"
      >
        {hours.map((h) => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </select>

      {/* Separator ":" */}
      <span className="text-[16px] md:text-[24px] font-medium">:</span>

      {/* Minute Dropdown */}
      <select
        value={minute}
        onChange={(e) => setMinute(e.target.value)}
        className="text-center text-[16px] md:text-[24px] font-medium border-none focus:outline-none w-1/4 bg-gray-100 p-2 rounded-lg shadow-md"
      >
        {minutes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>

      {/* AM/PM Toggle with Arrows */}
      <div className="flex flex-col items-center space-y-1 cursor-pointer" onClick={togglePeriod}>
        {/* Arrow Icon above the AM/PM Text */}
        <img
          src={leftarrow}
          alt="Toggle AM/PM"
          className="w-4 h-4 md:w-4 md:h-3 rotate-90 mb-1" // Adjust rotation and margin to position above text
        />
        
        {/* AM/PM Text */}
        <span className="text-[16px] md:text-[20px] font-semibold">{period}</span>
      </div>
      
    </div>







          <button className="w-full max-w-[500px] h-[60px] md:h-[90px] bg-[#FF6600] text-white rounded-[20px] flex justify-center items-center text-[24px] md:text-[40px] font-medium mt-8">
            Create
          </button>
        </div>
      </div>
    </div>
    
  );
};

export default Automateabilltv;

