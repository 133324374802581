import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import { useState } from 'react';


const Payinstantlyeducation = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };
  
    const [feeType, setFeeType] = useState("");
  const [studentName, setStudentName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [amount, setAmount] = useState("");
  const [studentId, setStudentId] = useState("");
  const [matricNumber, setMatricNumber] = useState("");
  const [faculty, setFaculty] = useState("");
  const [examType, setExamType] = useState("");

  // Forms based on fee type
  const formFields = {
    primarySecondary: ["Student ID", "Student Name", "School Name", "Amount to be paid"],
    tertiary: ["Matric Number", "Student Name", "Faculty/Department", "Amount to be paid"],
    professionalExam: ["Registration Number", "Candidate Name", "Exam Type", "Amount to be paid"],
  };
  console.log(formFields); // Logs the object to the console

  const handlePayment = () => {
    // Handle the education fee payment logic here
    console.log("Education payment initiated");
  };

    return (
      <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-[70rem] flex flex-col items-center">
        {/* Back Button */}
        <div className='w-full lg:w-[1057px]  mx-auto'> 
        <button
          onClick={handleBackClick}
          className="flex items-center w-16 h-16 md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
        </div>
  
  
        <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
      <div className="flex justify-between items-center mb-6">
        <p className="font-bold text-[24px] md:text-[32px]">School Fees & Education Levies</p>
      </div>

      {/* Fee Type Selection */}
      <select
        className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
        onChange={(e) => setFeeType(e.target.value)}
        value={feeType}
      >
        <option value="" disabled>Select fee type</option>
        <option value="primarySecondary">Primary/Secondary School Fees</option>
        <option value="tertiary">Tertiary Institution Fees</option>
        <option value="professionalExam">Professional Exam Fees</option>
      </select>

      {/* Conditional Fields Based on Fee Type */}
      {feeType === "primarySecondary" && (
        <>
          {/* Student ID */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter student ID"
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
          />

          {/* Student Name */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter student name"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />

          {/* School Name */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter school name"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
          />
        </>
      )}

      {feeType === "tertiary" && (
        <>
          {/* Matric Number */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter matric number"
            value={matricNumber}
            onChange={(e) => setMatricNumber(e.target.value)}
          />

          {/* Faculty/Department */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter faculty/department"
            value={faculty}
            onChange={(e) => setFaculty(e.target.value)}
          />
        </>
      )}

      {feeType === "professionalExam" && (
        <>
          {/* Registration Number */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter registration number"
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
          />

          {/* Candidate Name */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter candidate name"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />

          {/* Exam Type */}
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Enter exam type"
            value={examType}
            onChange={(e) => setExamType(e.target.value)}
          />
        </>
      )}

      {/* Amount to be Paid */}
      <div className="relative mb-6">
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">₦</span>
        <input
          type="number"
          className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] pl-10"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

      {/* Pay Now Button */}
      <div className="mt-8">
        <button
          onClick={handlePayment}
          className="w-full bg-[#FF6600] text-white py-3 rounded-lg font-semibold text-lg"
        >
          Pay Now
        </button>
      </div>
    </div>
      </div>
    );
  };

export default Payinstantlyeducation
