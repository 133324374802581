import React, { useState } from 'react';
import logo from './assets/logonotxt.png';
import { Link } from 'react-router-dom';

const Mainnav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <nav className="flex justify-between items-center p-[2rem]">
        {/* Logo and Brand Name */}
        <Link to="/">
        <div className="flex items-center cursor-pointer">
          <img src={logo} alt="Logo" className="w-[79.86px] h-[57.86px]" />
          <p className="hidden md:block text-[26px] font-semibold my-auto ml-3">AutoBillsPro</p>
        </div>
        </Link>

        {/* Menu for Larger Screens */}
        <div className="hidden md:flex space-x-6">
          <Link to="/">
            <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
              Home
            </span>
          </Link>
          <Link to="/how-it-works">
            <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
              How it works
            </span>
          </Link>
          <Link to="/Frequently-asked-questions">
            <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
              FAQs
            </span>
          </Link>
        </div>

        {/* Get Started Button */}
        <Link to="/signup">
        <button className="hidden md:block bg-[#FF6600] w-[170px] h-[54px] font-extrabold text-[20px] text-white text-center rounded-[25px] hover:bg-white hover:border-[#FF6600] border-[3px] hover:text-[#FF6600] duration-500">
          Get Started
        </button>
</Link>
        {/* Burger/X Menu Icon */}
        <div
          className="block md:hidden cursor-pointer"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <div className="w-6 h-6 flex flex-col justify-center items-center">
              <div className="w-6 h-[3px] bg-black transform rotate-45 mb-[-2px]"></div>
              <div className="w-6 h-[3px] bg-black transform -rotate-45"></div>
            </div>
          ) : (
            <div className="w-6 h-6 flex flex-col justify-center items-center">
              <div className="w-6 h-[3px] bg-black mb-1"></div>
              <div className="w-6 h-[3px] bg-black mb-1"></div>
              <div className="w-6 h-[3px] bg-black"></div>
            </div>
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="fixed top-0 left-0 w-[15rem] h-full bg-white shadow-lg md:hidden z-50">
          <div className="flex flex-col items-start space-y-4 p-6">
            <img src={logo} alt="Logo" className="w-[79.86px] h-[57.86px]" />
            <Link to="/">
              <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
                Home
              </span>
            </Link>
            <Link to="/how-it-works">
              <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
                How it works
              </span>
            </Link>
            <Link to="/frequently-asked-questions">
              <span className="text-[20px] font-bold hover:text-[#FF6600] duration-200 cursor-pointer">
                FAQs
              </span>
            </Link>
            <Link to="/signup">
        <button className=" bg-[#FF6600] w-[170px] h-[54px] font-extrabold text-[20px] text-white text-center rounded-[25px] hover:bg-white hover:border-[#FF6600] border-[3px] hover:text-[#FF6600] duration-500">
          Get Started
        </button>
</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mainnav;
