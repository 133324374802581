import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png'

import electricityicon from './assets/electricityicon.svg'
import tvicon from './assets/tvicon.svg'
import dataicon from './assets/daataicon.svg'
import phoneicon from './assets/phoneicon.svg'
import education from './assets/education.png'

const Automateabill = () => {

    const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className='bg-[#E0ECF5]  w-full min-h-screen  p-[2rem] h-full'>
      
        {/* backbutton */}
        <div className='w-full lg:w-[1057px]  mx-auto'>
      <button
    onClick={handleBackClick}
    className="flex  items-center w-[93px] h-[70px] bg-white rounded-full transition duration-200 ">
    <img src={arrowicon} alt="" className='mx-auto' />
  </button>
      </div>
  

<br />
<div className="bg-white p-4 md:p-[2rem] rounded-[20px] w-full max-w-[1057px] mx-auto">
  <h1 className="text-[24px] md:text-[36px] font-medium text-center md:text-left">Select a Category</h1>

  <div className="py-6 md:py-[3rem] px-4 md:px-[7rem] flex flex-wrap justify-center md:justify-evenly gap-y-8 md:gap-y-16">
    
    {/* 1 */}
    <Link to="/automate-bill-tv">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={tvicon} alt="TV Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">TV</h1>
      </div>
    </Link>

    {/* 2 */}
    <Link to="/automate-bill-electricity">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={electricityicon} alt="Electricity Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">Electricity</h1>
      </div>
    </Link>

    {/* 3 */}
    <Link to="/automate-bill-airtime">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={phoneicon} alt="Airtime Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">Airtime</h1>
      </div>
    </Link>

    {/* 4 */}
    <Link to="/automate-bill-data">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={dataicon} alt="Data Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">Data</h1>
      </div>
    </Link>

    {/* 5 */}
    <Link to="/automate-bill-education">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={education} alt="Data Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">Education</h1>
      </div>
    </Link>




    {/* 5 */}
    {/* <Link to="/automate-bill-custom">
      <div className="group border-2 border-[#FF6600] w-[140px] h-[120px] md:w-[195px] md:h-[155px] rounded-[10px] p-4 md:p-[3rem] cursor-pointer flex flex-col justify-center items-center">
        <img src={customicon} alt="Custom Icon" className="w-10 h-10 md:w-[44px] md:h-[44px]" />
        <h1 className="text-[#FF6600] font-bold text-center">Customize a bill</h1>
      </div>
    </Link> */}
    
  </div>


<p className='text-[20px] font-bold text-center'>
To create a personalized bill, <Link to="/automate-bill-custom"><span className='cursor-pointer text-[#FF6600]'>Click Here </span></Link>
</p>

 
</div>

    </div>
  )
}

export default Automateabill
