import React from 'react'
import './index.css';
import Sidenav from './sidenav'
import { Link } from 'react-router-dom';
import exclamstionicon from './assets/exclaminationicon.svg'
import statistics from './assets/statictsimg.svg'
import amountdueicon from './assets/Amount-dueicon.png'
import amountspentsfaricon from './assets/Amount spent so faricon.svg'
import requiredtopupimg from './assets/Required top-upicon.svg'
import plusicon from './assets/plusicon.svg'
import walleticon from './assets/walleticon.svg'
import walletstatusredimg from './assets/walletstatusredimg.svg'
import customicon from './assets/customicon.png'
import electricityicon from './assets/electricityicon.svg'
import tvicon from './assets/tvicon.svg'
import dataicon from './assets/daataicon.svg'
import phoneicon from './assets/phoneicon.svg'

const Dashboard = () => {
  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
      <Sidenav/>
      <br />
<div className='generaldiv bg-[#E0ECF5]  w-full p-[1rem] md:p-[3rem]'>
    
<div className='md:flex justify-between '>
    <div className='w-full md:w-[260px] h-[70] mb-[1rem] bg-white rounded-full text-center p-3 cursor-pointer'>
        <p className='text-[30px] font-bold'>Dashboard</p>
        </div>
        <Link to="/create-bill">
        
        <div className='w-full md:w-[260px] h-[70] bg-white rounded-full text-center p-3'>
        <p className='text-[30px] font-bold text-[#FF6600]'>Create a bill</p>
        </div></Link>
</div>

<br />
<br />


<div className='lg:flex justify-between gap-10'>
    {/* left div  */}
<div className='leftdiv w-full'>
{/* my-walllet-div */}

<div className=' w-full h-[231px] p-[2rem] bg-white rounded-[20px] flex flex-col justify-between'>
  {/* Top Section */}
  <div className='flex justify-between'>
    <span className='text-[24px] font-bold'>My Wallet</span>
    <img src={exclamstionicon} className='h-[25px] w-[25px]' alt="" />
  </div>

  {/* Bottom Section */}
  <div className='flex justify-between mt-auto'>
    <span className='text-[20px] my-auto'>Balance</span>
    <span className='text-[20px] md:text-[30px] font-bold'>₦205,830.00</span>
  </div>
</div>

<br />


<div className='md:flex gap-4 w-full'>
  <Link to="/add-money" className='w-full'>  
    <div className='flex justify-center items-center w-full  h-[68px] bg-white rounded-[20px] mb-[1rem] md:mb-0 '>
<img src={plusicon} alt="" className='w-[23px] h-[23px] ' />
<span className='text-[24px] text-[#FF6600] mr-[-12px] mx-2 font-bold'>
Add Money
</span>
    </div>
    </Link>


    <Link to="/withdraw" className='w-full'>
    <div className='flex justify-center items-center w-full  h-[68px] bg-white rounded-[20px] '>
<img src={walleticon} alt="" className='w-[23px] h-[23px] ' />
<span className='text-[24px] text-[#FF6600] mr-[-12px] mx-2 font-bold'>
Withdraw
</span>
    </div>
    </Link>
    
</div>

<br />

<div className='bg-white w-full h-[361px] p-[2rem] rounded-[20px]'>
    <p className='text-[24px] font-bold mb-5'>Wallet Status</p>

    <img src={walletstatusredimg} alt="" className='mx-auto' />
    <p className='text-[10px] md:text-[14px] opacity-80 text-center'>
Your wallet balance can’t handle the next
bills for the next 30days!
</p>
</div>

</div>

{/* left divend  */}
<br />


{/* rightdiv     */}
<div className='rightdiv w-full'>
{/* Statistics */}

<div className='  w-full h-auto md:h-[231px] p-[2rem] bg-white rounded-[20px] flex  justify-between'>

  <div className=''>
  <p className='text-[24px] font-bold '>Statistics</p>
  <p className='text-[8px] mb-3'>For October 2024</p>

  <div className='flex justify-between items-center my-2'>
    <span className='text-[12px] font-medium flex items-center'>
      <img src={amountdueicon} className='h-[20px] w-[20px] mr-2' alt="" />
      Amount due
    </span>
  </div>

  <div className='flex justify-between items-center my-2'>
    <span className='ext-[12px] font-medium flex items-center '>
      <img src={amountspentsfaricon} className='h-[20px] w-[20px] mr-2' alt="" />
      Amount spent so far
    </span>
  </div>

  <div className='flex justify-between items-center my-2'>
    <span className='ext-[12px] font-medium flex items-center'>
      <img src={requiredtopupimg} className='h-[20px] w-[20px] mr-2' alt="" />
      Required top-up
    </span>
  </div>

  </div>

  <div>
  <img src={statistics} alt="" className='w-[178px] h-[178.25p' />
  </div>

</div>

<br />

<div className='md:flex justify-evenly m-auto bg-white w-full h-auto md:h-[68px] rounded-[20px] p-3'>
<div className='my-auto mb-3 '>
    <p className='text-[20px] text-center font-bold'>₦350,500.00</p>
    <p className='text-[10px] text-center'>Amount due</p>
</div>

<div className='my-auto  mb-3'>
    <p className='text-[20px] text-center font-bold'>₦67,500.00</p>
    <p className='text-[10px] text-center'>Amount spent so far</p>
</div>

<div className='my-auto  mb-3 md:md-0'>
    <p className='text-[20px] text-center font-bold'>₦144,670.00</p>
    <p className='text-[10px] text-center'>Required top-up</p>
</div>





</div>

<br />


<div className='bg-white  w-full h-[361px] p-[2rem]  rounded-[20px]'>
    <div className='flex justify-between'>
    <p className='text-[24px] font-bold mb-5'>Top Bills</p>

    <div class="relative inline-block w-[94px] h-[30px]">

  <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-gray-700">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      
    </svg>
  </div>
</div>

    </div>
    
    <div class="overflow-x-auto">
  <table class="min-w-full text-left">
    <thead>
      <tr>
        <th class="py-3  text-[9px] md:text-[12px] opacity-70">#Title</th>
        <th class="py-3  text-[9px] md:text-[12px] opacity-70">#Details</th>
        <th class="py-3  text-[9px] md:text-[12px] opacity-70">#Due Date</th>
        <th class="py-3 text-[9px] md:text-[12px] opacity-70">#Amount</th>
      </tr>
    </thead>
    <tbody>

        {/* 1 */}
      <tr class="font-bold">

        <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={customicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">Custom</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">Rent</td>
        <td class="py-2 text-[10px] md:text-[14px]">23rd Nov - 12:30am</td>
        <td class="py-2 font-bold text-[10px] md:text-[14px]">₦191,000.00</td>
      </tr>


{/* 2 */}
      {/* <tr class="font-bold">
      <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={customicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">Custom</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">Rent</td>
        <td class="py-2 text-[10px] md:text-[14px]">23rd Nov - 12:30am</td>
        <td class="py-2 text-[10px] md:text-[14px]">₦191,000.00</td>
      </tr>

       */}

{/* 3 */}
      <tr class="font-bold">
      <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={tvicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">TV</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">DSTV</td>
        <td class="py-2 text-[10px] md:text-[14px]">31st Oct - 12:30pm</td>
        <td class="py-2 text-[10px] md:text-[14px]">₦21,000.00</td>
      </tr>


{/* 4 */}
      <tr class="font-bold">
      <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={electricityicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">Electricity</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">Ikeja Elect...</td>
        <td class="py-2 text-[10px] md:text-[14px]">21st Oct - 4:30pm</td>
        <td class="py-2 text-[10px] md:text-[14px]">₦10,000.00</td>
      </tr>


{/* 5 */}
<tr class="font-bold">
      <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={dataicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">Data</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">MTN</td>
        <td class="py-2 text-[10px] md:text-[14px]">1st Nov - 10:30pm</td>
        <td class="py-2 text-[10px] md:text-[14px]">₦8,000.00</td>
      </tr>

     

{/* 6 */}
       <tr class="font-bold">
      <td class="py-2 text-[10px] md:text-[14px]">  <div class="flex items-center">
        <img src={phoneicon} alt="" className='mr-2 w-[21px] h-[21px]'/>
            <span class="">Airtime</span>
          </div></td>
        <td class="py-2 text-[10px] md:text-[14px]">Airtel</td>
        <td class="py-2 text-[10px] md:text-[14px]">2nd Nov - 10:30pm</td>
        <td class="py-2 text-[10px] md:text-[14px]">₦5,000.00</td>
      </tr>


     
    </tbody>
  </table>
  
</div>


</div>

<br />
<br />
<br />

</div> {/* right divend */}


</div>




{/* end of general div  */}

</div>
    </div>
  )
}

export default Dashboard
